import { useContext } from 'react'
import { MobXProviderContext } from 'mobx-react'
import type { OptionAdvertisementBlocksStore } from '../stores/optionAdvertisementBlocks.store'
import type { OptionsStore } from '../stores/options.store'
import type { PlansStore } from '../stores/plans.store'
import type { SellerStore } from '../stores/seller.store'
import type { PublisherStore } from '../stores/publisher.store'
import type { EloPublisherStore } from '../stores/eloPublisher.store'
import type { TeamMemberStore } from '../stores/teamMember.store'
import type { SalesTeamMemberStore } from '../stores/salesTeamMember.store'
import type { PayerStore } from '../stores/payer.store'
import type { TrackingLogsStore } from '../stores/trackingLogs.store'
import type { UserStore } from '../stores/user.store'
import type { UserProfilesStore } from '../stores/userProfiles.store'
import type { CountriesStore } from '../stores/countries.store'
import type { AudioPlayersStore } from '../stores/audioPlayers.store'
import type { VatUserRegistrationsStore } from '../stores/vatUserRegistrations.store'
import type { VatCheckerStore } from '../stores/vatChecker.store'
import type { CurrenciesStore } from '../stores/currencies.store'

interface SharedStores {
  optionAdvertisementBlocksStore: OptionAdvertisementBlocksStore
  optionsStore: OptionsStore
  plansStore: PlansStore
  sellerStore: SellerStore
  publisherStore: PublisherStore
  eloPublisherStore: EloPublisherStore
  teamMemberStore: TeamMemberStore
  salesTeamMemberStore: SalesTeamMemberStore
  payerStore: PayerStore
  trackingLogsStore: TrackingLogsStore
  userStore: UserStore
  userProfilesStore: UserProfilesStore
  countriesStore: CountriesStore
  audioPlayersStore: AudioPlayersStore
  vatUserRegistrationsStore: VatUserRegistrationsStore
  vatCheckerStore: VatCheckerStore
  currenciesStore: CurrenciesStore
}

export const useSharedStores = () => useContext(MobXProviderContext) as unknown as SharedStores
