import { createUseStyles } from 'react-jss'

import { cleanupFonts } from 'utils/fonts.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { getAlignment } from '@elo-kit/utils/themes.utils'

import { COLORS, YES } from '@elo-kit/constants/general.constants'
import { AUDIO_PLAYER_Z_INDEX, AUDIO_TYPES } from '@elo-kit/constants/block.constants'

const { eloBlack, eloBlue, eloWhite, eloGrey20, eloGrey30, eloGrey40, eloGrey50, eloGrey70 } = COLORS

export const useAudioPlayerStyles = createUseStyles((theme = {}) => {
  const isDefaultView = theme.audioView === AUDIO_TYPES.default

  const getPaddingValue = (value, defaultValue, unit = '%') => {
    if (value || value === 0) {
      return `${value}${theme.paddingUnits || unit}`
    }

    return `${defaultValue}${theme.paddingUnits || unit}`
  }
  return {
    container: {
      height: '100%',
      maxWidth: '100%',
    },
    containerOverlay: {
      backgroundColor: theme.backgroundColor || eloGrey20,
      opacity: theme.transparency,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    audioContainer: {
      display: 'flex',
    },
    audioClose: {
      width: '100%',
      textAlign: 'right',
      marginTop: -8,
      marginRight: -45,
      color: eloGrey40,

      '& i': {
        '&:hover': {
          cursor: 'pointer',
          color: eloGrey50,
        },
      },

      '@media (max-width: 768px)': {
        display: 'none',
      },
    },
    mobileAudioClose: {
      display: 'none',

      '@media (max-width: 768px)': {
        display: 'flex',
        marginTop: -20,
        width: '100%',
        marginBottom: 20,
        borderBottom: `1px solid ${eloGrey30}`,
      },
    },
    mobileAudioIcon: {
      color: eloGrey40,

      '&:first-child': {
        width: '100%',
        textAlign: 'center',
        marginLeft: 24,
      },

      '&:hover': {
        cursor: 'pointer',
        color: eloGrey50,
      },

      '& .fa-times-circle': {
        marginRight: 7,
      },
    },
    filesContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: getAlignment(theme.horizontalAlignment),
      alignItems: getAlignment(theme.verticalAlignment),
      paddingTop: `${getPaddingValue(theme.paddingTop, 1)}`,
      paddingRight: `${getPaddingValue(theme.paddingRight, 20)}`,
      paddingLeft: `${getPaddingValue(theme.paddingLeft, 20)}`,
      paddingBottom: `${getPaddingValue(theme.paddingBottom, 1)}`,

      '@media (max-width: 768px)': {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    filesContainerNoPadding: {
      padding: 0,
    },
    fileContainer: {
      display: 'flex',
      width: `${theme.width || 100}%`,
      maxWidth: `${theme.maxWidth || 5000}px`,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',
      borderBottom: 'solid 1px #97979733',
      position: 'relative',
      backgroundColor: theme.playerBackgroundColor || eloWhite,
      borderRadius: `${theme.radius === 0 || theme.radius ? theme.radius : 8}px`,
      padding: '15px 30px',
      boxShadow: `${theme.shadow === YES ? '0 2px 7px 0 rgba(0, 0, 0, 0.15)' : 'none'}`,
      overflow: 'auto',
      '&:last-child': {
        borderBottom: 'none',
      },
      '& .elo-btn': {
        alignSelf: 'flex-start',
        minWidth: 'unset',
        marginLeft: 10,
      },
      '@media (max-width: 768px)': {
        padding: '20px 0',
        marginBottom: 0,
      },
      '@media (max-width: 576px)': {
        width: '100%',
      },
    },
    fixedPlayer: {
      width: '768px',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      margin: '0 auto',
      zIndex: AUDIO_PLAYER_Z_INDEX,
      borderRadius: '0',

      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
    fileDetails: {
      display: 'flex',
      flexDirection: 'column',
      width: '75%',
      '&.audio-player': {
        width: '100%',
      },
      '& .file-item-preview': {
        width: '37px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '@media (max-width: 768px)': {
        position: isDefaultView ? 'static' : 'relative',
      },
    },
    fileInfoContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',

      '@media (max-width: 768px)': {
        width: 'auto',
        flexDirection: 'column',
      },
    },
    fileName: {
      marginLeft: '10px',
      marginRight: '10px',
      wordBreak: 'break-word',
      color: theme.textColor || eloBlack,
      fontSize: theme.textSize ? `${theme.textSize}px` : '13px',
      fontFamily: cleanupFonts(theme.textFont) || 'Montserrat Medium',
      ...getFontStyles(theme.textStyle || 'bold'),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      '&.file': {
        width: '100%',
        margin: '0 0 -5px',
        '@media (max-width: 768px)': {
          width: 'auto',
          display: 'flex',
          alignSelf: 'flex-end',
          lineHeight: '1.2',
        },
      },
      '& .duration': {
        color: theme.progressColor || eloBlue,
        fontSize: 13,
        alignSelf: 'center',
        opacity: 1,
        whiteSpace: 'nowrap',
        marginRight: isDefaultView ? 0 : 8,

        '@media (max-width: 768px)': {
          display: isDefaultView ? 'none' : 'block',
        },
      },
    },
    mobileCollapsedFileName: {
      '&.file': {
        '@media (max-width: 768px)': {
          alignSelf: 'center',
        },
      },
    },
    audioTextInfo: {
      display: 'flex',
      alignItems: isDefaultView ? 'center' : 'baseline',
      flexDirection: isDefaultView ? 'row' : 'column',
      justifyContent: isDefaultView ? 'flex-start' : 'space-between',
      alignSelf: 'center',
      flexWrap: 'wrap',

      '@media (max-width: 768px)': {
        flexDirection: 'row',
      },
    },
    audioButtonContainer: {
      width: '45%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: isDefaultView ? 0 : 10,

      '& img': {
        width: 30,
        cursor: 'pointer',
      },

      '@media (max-width: 768px)': {
        display: 'none',
      },
    },
    audioButtons: {
      display: 'flex',
      alignItems: 'center',
      marginRight: isDefaultView && '25%',

      '& .rewind-button': {
        cursor: 'pointer',

        '&:active': {
          transform: 'scale(1.1)',
        },
      },
    },
    mobileAudioTextInfo: {
      '@media (max-width: 768px)': {
        display: 'flex',
        marginLeft: theme.backgroundImage ? 0 : 20,
      },
    },
    audioInfo: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: isDefaultView ? 'flex-end' : 'space-between',
      fontFamily: 'Montserrat Medium',
      marginTop: 5,

      '& .duration': {
        color: theme.progressColor || eloBlue,
        fontSize: 13,
        opacity: 1,
        whiteSpace: 'nowrap',
        marginRight: 10,
      },

      '& .all-duration': {
        color: theme.progressColor || eloBlue,
        fontSize: 13,
        alignSelf: 'center',
        opacity: 1,
        whiteSpace: 'nowrap',
        marginLeft: 8,
      },

      '@media (max-width: 768px)': {
        flexDirection: isDefaultView ? 'column' : 'row',
        display: 'none',
      },
    },
    audioSpeed: {
      marginBottom: 6,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      width: 'min-content',
      marginLeft: 'auto',
      '& i': {
        fontSize: 11,
        color: theme.progressColor || eloBlue,

        '& span': {
          fontFamily: 'Montserrat Reg',
          fontWeight: 'normal',
          color: theme.progressColor || eloBlue,
        },
      },
      '&:hover': {
        '& i': {
          color: `${theme.progressColor || eloBlue}CC`,

          '& span': {
            color: `${theme.progressColor || eloBlue}CC`,
          },
        },
      },
      '@media (max-width: 768px)': {
        marginBottom: 0,
      },
    },
    audioOptionsSpeed: {
      display: 'flex',

      '& .audio-option': {
        fontSize: 11,
        color: eloBlue,
        padding: '3px 5px',
        marginRight: 10,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        outline: 'none',
        border: 'none',

        '&:last-child': {
          marginRight: 0,
        },

        '&:hover': {
          color: eloBlack,
          backgroundColor: '#e8f2fb',
          borderRadius: 4,
        },
      },
      '& .audio-option--active': {
        color: eloBlack,
        backgroundColor: '#e8f2fb',
        borderRadius: 4,
      },
    },
    mainText: {
      fontFamily: cleanupFonts(theme.mainFont) || 'Montserrat Medium',
      fontSize: `${theme.mainSize || 16}px`,
      ...getFontStyles(theme.mainStyle || 'bold'),
      color: theme.mainColor || eloBlack,
      marginRight: 10,
      lineHeight: 1,
    },
    additionalText: {
      fontFamily: cleanupFonts(theme.additionalFont) || 'Montserrat Reg',
      fontSize: `${theme.additionalSize || 14}px`,
      ...getFontStyles(theme.additionalStyle || 'bold'),
      color: theme.additionalColor || eloGrey70,
      marginRight: 10,
    },
    defaultDuration: {
      alignSelf: 'baseline',
      marginLeft: theme.backgroundImage ? 0 : 20,
    },
    hideAudioSpeed: {
      '@media (max-width: 768px)': {
        display: 'none',
      },
    },
    audioProgressBar: {
      width: '100%',
      textAlign: 'center',
      height: 4,
      position: isDefaultView ? 'absolute' : 'static',
      top: 0,
      left: 0,
      borderRadius: 3,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    widgetAudioProgressBar: {
      borderRadius: isDefaultView ? 0 : 3,

      '@media (max-width: 768px)': {
        bottom: 0,
        top: 'unset',
      },
    },
    backgroundProgressBar: {
      backgroundColor: `${theme.progressColor || eloBlue}33`,
    },
    fileBackground: {
      background: `${theme.backgroundImage ? `url(${theme.backgroundImage})` : 'none'}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      height: 50,
      width: 50,
      borderRadius: 4,
    },
    audioImage: {
      marginRight: 15,
      backgroundColor: '#ececec',
      minWidth: 50,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      borderRadius: 4,

      '& i': {
        fontSize: 26,
        color: '#fff',
      },

      '@media (max-width: 768px)': {
        marginLeft: 20,
      },
    },
    audioButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: theme.buttonColor || eloBlue,
      border: `solid 2px ${theme.buttonColor || '#2b90d9'}`,
      borderRadius: '50%',
      backgroundColor: isDefaultView ? 'transparent' : theme.buttonColor || eloBlue,
      fontSize: '30px',
      textAlign: 'center',
      height: isDefaultView ? 40 : 30,
      width: isDefaultView ? 42 : 31,
      alignSelf: 'center',
      '&.play': {
        fontSize: isDefaultView ? 18 : 13,
      },
      '&.audio-button': {
        fontSize: isDefaultView ? 18 : 14,
        color: isDefaultView ? theme.buttonColor || eloBlue : eloWhite,
        margin: '0 23px',
      },
      '&.audio-button.pause:before': {
        marginLeft: 3,
      },
      '@media (max-width: 768px)': {
        width: isDefaultView ? 43 : 32,
      },
    },
    mobileNotCollapsedButton: {
      display: 'none',

      '@media (max-width: 768px)': {
        display: 'block',
        alignSelf: 'center',
        opacity: 0,
        width: 0,
        overflow: 'hidden',
      },

      '& .audio-button': {
        visibility: 'hidden',
      },
    },
    showNotCollapsedButton: {
      display: 'none',

      '@media (max-width: 768px)': {
        display: 'block',
        width: 'auto',
        opacity: 1,
        marginLeft: 'auto',
        overflow: 'unset',
      },

      '& .audio-button': {
        visibility: 'visible',
      },
    },
    hideCollapsedPlayButton: {
      width: 'auto',
    },
    mobileAudioPlayButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: theme.buttonColor || eloBlue,
      border: `solid 2px ${theme.buttonColor || '#2b90d9'}`,
      borderRadius: '50%',
      backgroundColor: isDefaultView ? 'transparent' : theme.buttonColor || eloBlue,
      fontSize: '30px',
      height: 40,
      width: 43,
      '&.play': {
        fontSize: 18,
      },
      '&.audio-button': {
        fontSize: 18,
        color: isDefaultView ? theme.buttonColor || eloBlue : eloWhite,
        marginRight: 30,
        marginLeft: 30,
      },
      '&.audio-button.pause:before': {
        marginLeft: 3,
      },
      '@media (max-width: 768px)': {
        display: 'flex',
      },
    },
    mobileCollapsed: {
      display: 'none',

      '& .fields-container__horisontal-separator': {
        marginBottom: 20,
      },
      '@media (max-width: 768px)': {
        opacity: 1,
        transition: 'all .9s',
        height: '70px',
        display: 'block',
        width: '100%',
        marginTop: 20,
      },
    },
    mobileAudioInfo: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Montserrat Medium',
      padding: '0 20px',

      '& .duration': {
        color: theme.progressColor || eloBlue,
        fontSize: 13,
        opacity: 1,
        whiteSpace: 'nowrap',
        marginRight: isDefaultView ? 0 : 10,
        minWidth: '42px',
      },

      '& .all-duration': {
        color: theme.progressColor || eloBlue,
        fontSize: 13,
        alignSelf: 'center',
        opacity: 1,
        whiteSpace: 'nowrap',
        marginLeft: 8,
      },
    },
    mobileAudioButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 'auto',
      fontFamily: 'Montserrat Medium',
      marginTop: 5,
    },
    mobileAudioButtons: {
      display: 'flex',
      margin: !isDefaultView && '8px auto 0',
      alignItems: 'center',

      '& .rewind-button': {
        cursor: 'pointer',

        '&:active': {
          transform: 'scale(1.1)',
        },
      },
    },
    hideMobileCollapsed: {
      marginTop: 0,
      height: 0,
      opacity: 0,
      overflow: 'hidden',
    },
  }
})
